import React from 'react';
import Spring from '@components/Spring';
import styles from './style.module.scss';

const StockItemComponent = ({ currency, ask, bid, i, isOpen, oTime, onClick, className, onTradeAction }) => {
    const iconPath = `../../assets/symbols/${currency.toLowerCase()}.png`;

    const handleTradeAction = (action) => {
        if (onTradeAction) {
            onTradeAction({ action, currency });
        }
    };
	
	const DateTimeDisplay = ({ isoDate }) => {
		if (!isoDate) {
			return <div>-</div>;
		}

		const openDate = new Date(isoDate);
		const localDate = new Date(openDate.getTime() + (openDate.getTimezoneOffset() * 60000));

		const openDay = new Intl.DateTimeFormat('tr-TR', { weekday: 'long' }).format(localDate);

		const timePart = isoDate.split('T')[1];
		const openTime = timePart ? timePart.split(':').slice(0, 2).join(':') : '00:00';

		const formattedOpenTime = `${openDay}, ${openTime}`;

		return (
			<div style={{textAlign: 'center'}}>
				<div style={{fontSize: '11px'}}>{openDay}</div>
				<div style={{fontSize: '11px'}}>{openTime}</div>
			</div>
		);
	};
	
    return (
        <Spring type="list" index={i}>
            <div className={`card ${className}`} onClick={onClick}>
                <div className={styles['container']}>
                    <div className={`${styles['d-flex']} ${styles['align-items-center']} ${styles['justify-content-between']}`}>
                        <div className={`${styles['symbol-section']} ${styles['column-price']} ${styles['align-items-center']}`}>
                       {isOpen ? (
                                <>
									<span className={styles['bid-price']}>{bid}</span>
									<button 
										className={styles['tradeButtonSell']} 
										style={{ marginTop: '4px' }} 
										onClick={(e) => {
											e.stopPropagation();
											handleTradeAction('Sell');
										}} 
										onTouchStart={(e) => {
											e.stopPropagation();
											handleTradeAction('Sell');
										}}
									>
										Sat
									</button>
                                </>
                            ) : (
								
                                <span style={{fontSize: '11px'}}>Piyasa <br /> Kapalı</span>
                            )}
                        </div>
                        <div className={`${styles['symbol-section']} ${styles['column']}`}>
                            <img src={iconPath} alt={currency} className={styles['crypto-iconn']} />
                            <h4 className={styles['currency-symbol']}>{currency}</h4>
                        </div>
						
						{isOpen}
                        <div className={`${styles['symbol-section']} ${styles['column-price']} ${styles['align-items-center']}`}>
                       {isOpen ? (
                                <>
                                    <span className={styles['ask-price']}>{ask}</span>
                                    <button 
                                        className={styles['tradeButtonBuy']} 
                                        style={{ marginTop: '4px' }} 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleTradeAction('Buy');
                                        }}
                                        onTouchStart={(e) => {
                                            e.stopPropagation();
                                            handleTradeAction('Buy');
                                        }}
                                    >
                                        Al
                                    </button>
                                </>
                            ) : (
                                <span><DateTimeDisplay isoDate={oTime} /></span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Spring>
    );
};

const StockItem = React.memo(StockItemComponent, (prevProps, nextProps) => {
    return prevProps.currency === nextProps.currency &&
           prevProps.ask === nextProps.ask &&
           prevProps.bid === nextProps.bid &&
           prevProps.i === nextProps.i &&
           prevProps.className === nextProps.className;
});

export default StockItem;
