import { useParams } from 'react-router-dom';
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import Transactions from '@widgets/m/Transactions';



const MTransactions = () => {
    return (
        <AppLayout>
            <PageHeader title="İşlemlerim"/>
            <Transactions/>
        </AppLayout>
    )
}

export default MTransactions

