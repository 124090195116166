




import { useParams } from 'react-router-dom';
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import TraderDetail from '@widgets/TraderDetail';
import TraderDetailM from '@widgets/m/TraderDetail';
import WalletOverview from '@widgets/WalletOverview';
import Footer from '@widgets/Footer';

import { useWindowSize } from 'react-use';

const mobileWidgets = {

};

const desktopWidgets = (id) => ({
    wallet: <WalletOverview />,
    deposit: <TraderDetail traderId={id}/>,
});



const TraderDetailPage = () => {
	const { width } = useWindowSize();
	const isMobile = width < 990;
	let { id } = useParams();

	return (
        <AppLayout>
            <PageHeader title="Trader"/>
			{isMobile && <TraderDetailM traderId={id} />}
			<AppGrid id="wallet" widgets={isMobile ? mobileWidgets : desktopWidgets(id)}/>
			{!isMobile && <Footer/>}
        </AppLayout>
	);
}
export default TraderDetailPage


