// components
import Spring from '@components/Spring';
import styles from './style.module.scss';

import React, { useContext  } from 'react';
import {useModal} from '@contexts/modalContext';
import { tradersContext } from '@contexts/tradersContext';

const TraderItem = ({trader, i, onClick}) => {
	const {handleOpen} = useModal();
	const { traders, loadTraders } = useContext(tradersContext);

	const handleFollow = () => {
		handleOpen('payment', { trader, actionType: 'new' });
	};

	const handleEditFollow = () => {
		handleOpen('payment', { trader, actionType: 'edit' });
	};
	

    return (
	<>
        <Spring className={styles.traderItem} index={i} type="list">
            <div className={styles.traderImage} onClick={onClick} onTouchStart={onClick}>
                <img style={{borderRadius: '50%'}} src={trader.imageUrl} />
            </div>
            <div className="d-flex flex-column" onClick={onClick} onTouchStart={onClick}>
                <h3 className="text-overflow">{trader.name}</h3>
                <span>{trader.followCount} Takipçi</span>
            </div>
            <div className="flex-1">
                <div className="d-flex" style={{justifyContent: 'end'}}>
					{trader.isFollow ? (
						<button className="btn btn--pill btn--invert"  onClick={handleEditFollow} onTouchStart={handleEditFollow}>
						  Takip Ediliyor
						</button>
					) : (
						<button className="btn btn--pill btn--invert" style={{width:'100px', fontSize: '11px'}} onClick={handleFollow} onTouchStart={handleFollow}>
						  Takip Et
						</button>
					)}
                </div>
            </div>
        </Spring>
		<div className="d-flex flex-column g-8 border-bottom">
			<div className="d-flex justify-content-between" style={{margin: '0px 15px 17px 15px'}}>
				<div className={styles.box} style={{ flex: 1, textAlign: 'center' }}>
					<label>Bu Ay</label>
					<div className={styles.boxText}>
						<span className="h3">{trader.currentROI}</span>
					</div>
				</div>
				<div className={styles.box} style={{ flex: 1, textAlign: 'center' }}>
					<label>Tüm Zamanlar</label>
					<div className={styles.boxText}>
						<span className="h3">{trader.allROI}</span>
					</div>
				</div>
			</div>
		</div>
		</>
    )
}

export default TraderItem

