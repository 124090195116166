import React, { useEffect, useRef, useContext, useState} from 'react';
import { symbolsContext } from '@contexts/symbolsContext';
import { useThemeProvider } from '@contexts/themeContext';

const ChartDetail = ({ symbol }) => {

	const {theme} = useThemeProvider();
    const widgetRef = useRef(null);
	const { symbols } = useContext(symbolsContext);
	const [symbolData, setSymbolData] = useState('');

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://s3.tradingview.com/tv.js';
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			if (widgetRef.current) {
				widgetRef.current.remove();
			}
			createWidget();
		};
		return () => {
			if (widgetRef.current && widgetRef.current.parentNode) {
				widgetRef.current.remove();
			}
		};
	}, [symbolData]);

	useEffect(() => {
		if (symbols) {
			const foundSymbol = symbols.find(s => s.symbol === symbol);
			if (foundSymbol) {
				setSymbolData(foundSymbol.chart);
			}
		}
	}, [symbol, symbols]);


    const createWidget = () => {

        widgetRef.current = new window.TradingView.widget({
            "width": "100%",
            "height": 500,
            "symbol": symbolData,
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": theme === 'dark' ? 'Dark' : 'Light',
            "style": "1",
            "locale": "tr",
            "toolbar_bg": "#f1f3f6",
            "enable_publishing": false,
            "withdateranges": false,
            "hide_side_toolbar": true,
            "allow_symbol_change": true,
            "show_popup_button": false,
            "popup_width": "1000",
            "popup_height": "650",
            "container_id": "tradingview_e8053",
            "hide_volume": true,
            "hide_legend": true,
            "save_image": false
        });
    };

    return <div style={{marginBottom: '80px', padding: '10px'}}id="tradingview_e8053" />;

}

export default ChartDetail