import React, { createContext, useState, useEffect, useCallback } from 'react';
import { fetchTraders } from '@services/apiService';
import { useAuth } from '@contexts/AuthContext';

export const tradersContext = createContext();

export const TradersProvider = ({ children }) => {
  const { authToken } = useAuth();
  const [traders, setTraders] = useState(() => {
    const savedTraders = localStorage.getItem('traders');
    return savedTraders ? JSON.parse(savedTraders) : [];
  });

  const loadTraders = useCallback(async () => {
    if (authToken) {
      try {
        const tradersData = await fetchTraders(authToken);
        localStorage.setItem('traders', JSON.stringify(tradersData));
        setTraders(tradersData);
      } catch (error) {
        console.error('Traders data fetching error:', error);
        setTraders([]);
      }
    } else {
      localStorage.removeItem('traders');
      setTraders([]);
    }
  }, [authToken]);

  useEffect(() => {
    loadTraders();
  }, [loadTraders, authToken]);

  return (
    <tradersContext.Provider value={{ traders, loadTraders }}>
      {children}
    </tradersContext.Provider>
  );
};
