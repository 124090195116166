import React, { createContext, useState, useEffect, useCallback } from 'react';
import { fetchRobots } from '@services/apiService';
import { useAuth } from '@contexts/AuthContext';

export const robotsContext = createContext();

export const RobotsProvider = ({ children }) => {
  const { authToken } = useAuth();
  const [robots, setRobots] = useState(() => {
    const savedRobots = localStorage.getItem('robots');
    return savedRobots ? JSON.parse(savedRobots) : [];
  });
  const loadRobots = useCallback(async () => {
    if (authToken) {
      try {
        const robotsData = await fetchRobots(authToken);
        localStorage.setItem('robots', JSON.stringify(robotsData));
        setRobots(robotsData);
      } catch (error) {
        console.error('Robots data fetching error:', error);
        setRobots([]);
      }
    } else {
      localStorage.removeItem('robots');
      setRobots([]);
    }
  }, [authToken]);

  useEffect(() => {
    loadRobots();
  }, [loadRobots, authToken]);

  return (
    <robotsContext.Provider value={{ robots, loadRobots }}>
      {children}
    </robotsContext.Provider>
  );
};
