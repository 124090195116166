import React, { useState, useEffect } from 'react';

function LabelFieldCheckbox({ minAmount, maxAmount, buyPrice, sellPrice, tab, id, setMarketLimit, setStopLoss, setTakeProfit }) {
	
	const [isMarketLimitActive, setIsMarketLimitActive] = useState(false);
	const [isStopLossActive, setIsStopLossActive] = useState(false);
	const [isTakeProfitActive, setIsTakeProfitActive] = useState(false);
	const [marketLimitAmount, setMarketLimitAmount] = useState(buyPrice);
	const [stopLossAmount, setStopLossAmount] = useState(buyPrice);
	const [takeProfitAmount, setTakeProfitAmount] = useState(buyPrice);

	const [marketLimitDirty, setMarketLimitDirty] = useState(false);
	const [stopLossDirty, setStopLossDirty] = useState(false);
	const [takeProfitDirty, setTakeProfitDirty] = useState(false);

	useEffect(() => {
		setIsMarketLimitActive(false);
		setIsStopLossActive(false);
		setIsTakeProfitActive(false);
		setMarketLimitDirty(false);
		setStopLossDirty(false);
		setTakeProfitDirty(false);

	}, [tab]);
  
	useEffect(() => {
		if (isMarketLimitActive) {
			if (!marketLimitDirty) {
				setMarketLimitAmount(buyPrice);
			}
		} else {
			setMarketLimitAmount(null);
			setMarketLimitDirty(false);
		}
		if (isStopLossActive) {
			if (!stopLossDirty) {
				setStopLossAmount(buyPrice);
			}
		} else {
			setStopLossAmount(null);
			setStopLossDirty(false);
		}
		if (isTakeProfitActive) {
			if (!takeProfitDirty) {
				setTakeProfitAmount(buyPrice);
			}
		} else {
			setTakeProfitAmount(null);
			setTakeProfitDirty(false);
		}
	}, [isMarketLimitActive, isStopLossActive, isTakeProfitActive, buyPrice, marketLimitDirty, stopLossDirty, takeProfitDirty]);



	useEffect(() => {
		setMarketLimit(marketLimitAmount);
		setStopLoss(stopLossAmount);
		setTakeProfit(takeProfitAmount);
	}, [marketLimitAmount, stopLossAmount, takeProfitAmount]);

	const calculateStep = (value) => {
		const valueStr = value.toString();
		if (valueStr.includes('.')) {
			const parts = valueStr.split('.');
			const integerPart = parts[0];
			const decimalPart = parts[1];
			if (decimalPart === '0' || decimalPart === undefined) {
				return 1;
			} else {
				return integerPart.length === 4 ? 0.1 : 0.01;
			}
		}
		return 1;
	};
	
	const step = calculateStep(buyPrice);

	const increaseMarketLimitAmount = () => {
		setMarketLimitAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) + step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
		setMarketLimitDirty(true);
	};

	const decreaseMarketLimitAmount = () => {
		setMarketLimitAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) - step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
		setMarketLimitDirty(true);
	};

	const increaseStopLossAmount = () => {
		setStopLossAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) + step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
		setStopLossDirty(true);
	};

	const decreaseStopLossAmount = () => {
		setStopLossAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) - step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
		setStopLossDirty(true);
	};

	const increaseTakeProfitAmount = () => {
		setTakeProfitAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) + step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
		setTakeProfitDirty(true);
	};

	const decreaseTakeProfitAmount = () => {
		setTakeProfitAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) - step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
		setTakeProfitDirty(true);
	};

		
	const handleStopLossChange = (value) => {
		setStopLossAmount(value);
		setStopLossDirty(true);
	};

	const handleTakeProfitChange = (value) => {
		setTakeProfitAmount(value);
		setTakeProfitDirty(true);
	};

		

  return (
    <>
      {/* Market Limit Bölümü */}
      <div className="input-group-trade" style={{ marginTop: '10px', display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <label className="checkbox-container">
		<input 
          type="checkbox"
          className="checkbox marketLimit checkboxMarket toggleCheckbox"
          style={{ cursor: 'pointer' }}
            checked={isMarketLimitActive}
            onChange={e => setIsMarketLimitActive(e.target.checked)}
        />
		<span className="checkmark"></span>
		</label>
        <span
          className="input-group-text-trade marketLimit togglediv"
          style={{ cursor: 'pointer', padding: '7px 5px', marginTop: '10px',  minWidth: '30%', fontSize: '13px' }}
        >
          Market Limit
        </span>
        {isMarketLimitActive && (
          <span className="currencyAmount">
            <div className="input-group-trade" style={{ marginTop: '10px', display: 'flex', alignItems: 'center', width: '100%' }}>
              <button className="btn" onClick={decreaseMarketLimitAmount} style={{ width: '35px', height: '35px' }}>-</button>
              <input type="number" id="marketLimit"
				   value={marketLimitAmount}
				   onChange={e => setMarketLimitAmount(e.target.value)}
				   className="field-input marketLimitInput" 
				   style={{ height: '35px', textAlign: 'center', maxWidth: '50%' }} />
              <button className="btn" onClick={increaseMarketLimitAmount} style={{ width: '35px', height: '35px' }}>+</button>
            </div>
          </span>
        )}
      </div>

      {/* Stop Loss Bölümü */}
      <div className="input-group-trade" style={{ marginTop: '10px', display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <label className="checkbox-container">
		<input
          type="checkbox"
          className="checkboxLarge stopLoss checkboxMarket toggleCheckbox"
          style={{ cursor: 'pointer' }}
            checked={isStopLossActive}
            onChange={e => setIsStopLossActive(e.target.checked)}
		  className="field-input marketLimitInput" 
        />
		<span className="checkmark"></span>
		</label>
        <span
          className="input-group-text-trade stopLoss togglediv"
          style={{ cursor: 'pointer', padding: '7px 5px', marginTop: '10px', minWidth: '30%', fontSize: '13px' }}>
          Zarar Durdur
        </span>
        {isStopLossActive && (
          <span className="currencyAmount">
            <div className="input-group-trade" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <button className="btn" onClick={decreaseStopLossAmount} style={{ width: '35px', height: '35px' }}>-</button>
              <input type="text" id="stopLoss" value={stopLossAmount} 
			  onChange={(e) => handleStopLossChange(e.target.value)}
			  className="field-input stopLossInput" style={{ height: '35px', textAlign: 'center', maxWidth: '50%' }} />
              <button className="btn" onClick={increaseStopLossAmount} style={{ width: '35px', height: '35px' }}>+</button>
            </div>
          </span>
        )}
      </div>
	  
	  {/* Take Profit Bölümü */}
      <div className="input-group-trade" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <label className="checkbox-container">
		<input
          type="checkbox"
          className="checkboxLarge takeProfit checkboxMarket toggleCheckbox"
          style={{ cursor: 'pointer' }}
            checked={isTakeProfitActive}
            onChange={e => 
			setIsTakeProfitActive(e.target.checked)
			}
        />
		<span className="checkmark"></span>
		</label>
        <span
          className="input-group-text-trade takeProfit togglediv"
          style={{ cursor: 'pointer', padding: '7px 5px', marginTop: '10px', minWidth: '30%', fontSize: '13px' }}
        >
          Kar Al
        </span>
        {isTakeProfitActive && ( 
          <span className="currencyAmount">
            <div className="input-group-trade" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <button className="btn" onClick={decreaseTakeProfitAmount} style={{ width: '35px', height: '35px' }}>-</button>
              <input type="text" id="takeProfit" value={takeProfitAmount} 
			  onChange={(e) => handleTakeProfitChange(e.target.value)}
			  className="field-input takeProfitInput" style={{ height: '35px', textAlign: 'center', maxWidth: '50%' }} />
              <button className="btn" onClick={increaseTakeProfitAmount} style={{ width: '35px', height: '35px' }}>+</button>
            </div>
          </span>
        )}
      </div>
    </>
  );
}

export default LabelFieldCheckbox;
