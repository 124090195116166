import React, { createContext, useState, useEffect, useCallback } from 'react';
import { fetchGetFinances } from '@services/apiService';
import { useAuth } from '@contexts/AuthContext';

export const financeContext = createContext();

export const FinanceProvider = ({ children }) => {
  const { authToken } = useAuth();
  const [userFinance, setUserFinance] = useState(null);

  // Finans bilgilerini API'dan çekmek için bir fonksiyon
  const loadUserFinance = useCallback(async () => {
    if (!authToken) return;

    try {
      const financeData = await fetchGetFinances(authToken);
      if (financeData && financeData.data) {
        setUserFinance(financeData.data);
        localStorage.setItem('userFinance', JSON.stringify(financeData.data));
      }
    } catch (error) {
      console.error('Error loading finances:', error);
      localStorage.removeItem('userFinance');
      setUserFinance(null);
    }
  }, [authToken]);
  useEffect(() => {
    loadUserFinance();
  }, [loadUserFinance]);

  return (
    <financeContext.Provider value={{ userFinance, refreshFinance: loadUserFinance }}>
      {children}
    </financeContext.Provider>
  );
};
