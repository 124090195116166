// styling
import styles from './style.module.scss';

// components
import Spring from '@components/Spring';
// utils
import classNames from 'classnames';
import {memo} from 'react';

// hooks
import {useThemeProvider} from '@contexts/themeContext';

// constants
import ACTIONS from '@constants/MobileActions';


import { useNavigate } from 'react-router-dom';

import walletLight from '@assets/icons/icon-wallet-light.svg';
import walletDark from '@assets/icons/icon-wallet-dark.svg';

const QuickActions = () => {
    const {theme} = useThemeProvider();
	const navigate = useNavigate();
	
    return (
        <Spring className="widget">
            <div className={classNames('widget_body card g-0', {'card--no-bg': theme === 'light'})}>
                <div className="d-grid g-16" style={{marginBottom: '15px'}}>
                    <button className={`${styles.button} card ${theme === 'light' ? 'bordered' : ''}`} onClick={() => navigate('/Verification')}>
                    <span className="d-inline-flex align-items-center g-8">
                        <img className="symbol" src={theme === 'light' ? walletLight : walletDark} alt="icon"/>
                        <h4>Kimlik Doğrulama</h4>
                    </span>
                        <i className="icon-chevron-right text-dark" />
                    </button>
                </div>
				<div className={styles.list}>
					{ACTIONS.map((action) => (
						<div 
							className={`${styles.list_item} card bordered bordered--hover`} 
							key={action.title}
							onClick={() => navigate(action.url)}>
							<div className={styles.content}>
								<div className="symbol">{action.icon}</div>
								<h4>{action.title}</h4>
								{action.primary && <i className="icon icon-chevron-right"/>}
							</div>
						</div>
					))}
				</div>
		
            </div>
        </Spring>
    )
}

export default memo(QuickActions);