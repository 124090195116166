// utils
import PropTypes from 'prop-types';

const CurrencyItem = ({name, label, withIcon = true}) => {
	const iconPath = `../../assets/symbols/${name.toLowerCase()}.png`;
    return (
        <div className="d-flex align-items-center g-8" style={{width: '150px'}}>
            {
                withIcon &&
                <div className="crypto-icon">
                    <img src={iconPath} alt={name} />
                </div>
            }
            <div className="d-flex flex-column" style={{width: 90}}>
                <h3 className="text-overflow">{name}</h3>
                <span>{label}</span>
            </div>
        </div>
    )
}

CurrencyItem.propTypes = {
    name: PropTypes.string.isRequired,
    withIcon: PropTypes.bool
}

export default CurrencyItem