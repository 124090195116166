// styling
import styles from './style.module.scss';

// components
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {NavLink} from 'react-router-dom';
import Switch from '@ui/Switch';

// hooks
import {useSidebar} from '@contexts/sidebarContext';
import {useWindowSize} from 'react-use';
import {useThemeProvider} from '@contexts/themeContext';
import {useModal} from '@contexts/modalContext';
import useAuthRoute from '@hooks/useAuthRoute';
import { useAuth } from '@contexts/AuthContext';

// utils
import {memo} from 'react';

// constants
import LINKS from '@constants/links';
import MOBILELINKS from '@constants/MobileLinks';
import notifications from '@db/notifications.js';

// assets
import logo from '@assets/logo.png';
import avatar from '@assets/avatar.webp';
import {ReactComponent as DepositIcon} from '@assets/icons/deposit.svg';
import {ReactComponent as WithdrawIcon} from '@assets/icons/withdraw.svg';
import {ReactComponent as RobotsIcon} from '@assets/icons/staking.svg';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const {open, setOpen} = useSidebar();
    const {modal, handleOpen} = useModal();
    const {theme, setTheme} = useThemeProvider();
    const isTemporary = useWindowSize().width < 1920;
    const { width } = useWindowSize();
    const isMobile = width < 990;
    const unreadNotifications = notifications.filter(notification => !notification.read).length;
    const isActive = (path) => window.location.pathname === path;
    const isAuthRoute = useAuthRoute();
	const navigate = useNavigate();
	const { logout } = useAuth();

    const handleLogout = () => {
        logout();
    };

    return (
        <>
            {
                !isAuthRoute ? (
                    <SwipeableDrawer
                        anchor="left"
                        open={isTemporary ? open : true}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        variant={isTemporary ? 'temporary' : 'persistent'}
                        PaperProps={{
                            sx: {
                                width: 310,
                                padding: '32px 32px 40px',
                                backgroundColor: 'var(--widget)',
                                color: theme === 'light' ? 'var(--header)' : 'var(--text)',
                                fontSize: '1rem',
  
                                alignItems: 'flex-start',
                                height: '100%',
                                fontWeight: '600 !important'
                            }
                        }}>
                        <NavLink className={styles.logo} to="/">
                            <img src={logo} alt="logo" style={{width: '70%'}}/>
                        </NavLink>
							{isMobile && (
								<div className={`${styles.list} flex-1`}>
								{MOBILELINKS.map(link => (
									<NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
											 key={link.desktop}
											 to={link.path}>
											<span className="wrapper">
												<i className={`icon icon-${link.icon === 'home' ? 'dashboard' : link.icon} ${link.icon === 'collections' ? styles.sm : ''} ${isActive(link.path) ? '' : 'active'}`}/>
												<i className={`icon icon-fill ${isActive(link.path) ? 'active' : ''} icon-${link.icon === 'home' ? 'dashboard' : link.icon}${link.icon !== 'trade' ? '-active' : ''} ${link.icon === 'collections' ? styles.sm : ''}`}/>
											</span>
										{link.desktop}
									</NavLink>
								))}
								</div>
                            )}
							
                        <div className="d-flex flex-column g-40 w-100">
                            <div className={styles.list}>
                                <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                         key={'dashboard'}
                                         to={'/'}>
										<i className={`icon icon-trade ${isActive('/') ? 'active' : ''} icon-${'trade' === 'home' ? 'dashboard' : 'trade'}${'trade' !== 'trade' ? '-active' : ''} ${'trade' === 'collections' ? styles.sm : ''}`}/>
										Piyasalar
                                </NavLink>
                                <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                         key={'wallet'}
                                         to={'/wallet'}>
										<i className={`icon icon-wallet ${isActive('/wallet') ? 'active' : ''} icon-${'wallet' === 'home' ? 'dashboard' : 'wallet'} ${'wallet' !== 'wallet' ? '-active' : ''} ${'wallet' === 'collections' ? styles.sm : ''}`}/>
										Hesabım
                                </NavLink>
                                <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                         key={'verification'}
                                         to={'/verification'}>
										<i className={`icon icon-user ${isActive('/Verification') ? 'active' : ''} icon-${'user' === 'home' ? 'dashboard' : 'user'} ${'user' !== 'user' ? '-active' : ''} ${'user' === 'collections' ? styles.sm : ''}`}/>
										Kimlik Doğrulama
                                </NavLink>
                                <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                         key={'deposit'}
                                         to={'/deposit'}>
										<div className="symbol"><DepositIcon /></div>
										Para Yatır
                                </NavLink>
                                <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                         key={'withdrawal'}
                                         to={'/withdrawal'}>
										<div className="symbol"><WithdrawIcon /></div>
										Para Çek
                                </NavLink>
                                <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                         key={'robots'}
                                         to={'/robots'}>
										<div className="symbol"><RobotsIcon /></div>
										Robotlar
                                </NavLink>
								<button  className={`nav-link nav-link--sidebar ${styles.list_item}`} onClick={handleLogout}>
										<i className={`icon icon-logout ? 'active' : ''} icon-${'logout' === 'home' ? 'dashboard' : 'logout'} ${'logout' !== 'logout' ? '-active' : ''} ${'logout' === 'collections' ? styles.sm : ''}`}/>
										Oturumu Kapat
								</button>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className={styles.list_item}>
                                <span>
                                    <i className={`${styles.sm} icon-theme`}/>
                                </span>
                                        Tema
                                    </div>
                                    <Switch id="theme"
                                            checked={theme === 'dark'}
                                            onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}/>
                                </div>
								
								
                            </div>
							{isMobile && (
								<div className="d-flex align-items-center g-16 text-header">
									<img className="avatar" src={avatar} alt="Dan Tourlan"/>
									<button className="text-600" onClick={() => handleOpen('account')}>
										Dan Tourlan
									</button>
								</div>
							)}
                        </div>
                    </SwipeableDrawer>
                ) : null
            }
        </>
    )

}

export default memo(Sidebar);