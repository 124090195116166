import { useEffect } from 'react';
import axios from 'axios';

const useAuthInterceptor = (setAuthToken, setUserProfile, navigate) => {
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('userToken');
          setAuthToken(null);
          setUserProfile(null);
          navigate('/sign-in');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [setAuthToken, setUserProfile, navigate]);
};

export default useAuthInterceptor;
