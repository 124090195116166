import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useAuth } from '@contexts/AuthContext';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const { authToken } = useAuth();
    const [webSocket, setWebSocket] = useState(null);
    const retryTimeout = useRef(null);
    const retryInterval = 5000; // Yeniden bağlanma deneme süresi (ms)

    const connectWebSocket = () => {
        const ws = new WebSocket(`wss://socket.yatirimatlas.net:2053/websocket?auth=${authToken}`);

        ws.onopen = () => {
            //console.log("WebSocket Connected");
            clearTimeout(retryTimeout.current);
        };
        
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            //console.log("WebSocket Message:", data);
        };

        ws.onclose = () => {
            //console.log("WebSocket Disconnected");
            retryTimeout.current = setTimeout(() => connectWebSocket(), retryInterval);
        };

        ws.onerror = (error) => {
            //console.error("WebSocket Error:", error);
            ws.close();
        };

        setWebSocket(ws);
    };

    useEffect(() => {
        if (authToken) {
            connectWebSocket();
        }

        return () => {
            if (webSocket) {
                webSocket.close();
            }
            clearTimeout(retryTimeout.current);
        };
    }, [authToken]);

    return (
        <WebSocketContext.Provider value={webSocket}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
