import {useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';

const useAuthRoute = () => {
    const authRoutes = ['/sign-in', '/sign-up'];
    const location = useLocation();
    const [isAuthRoute, setIsAuthRoute] = useState(false);

    useEffect(() => {
        const isAuthRoute = authRoutes.includes(location.pathname);
        setIsAuthRoute(isAuthRoute);

        return () => {
            setIsAuthRoute(false);
        }
    }, [location]);

    return isAuthRoute;
}

export default useAuthRoute