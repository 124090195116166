import {ReactComponent as DepositIcon} from '@assets/icons/deposit.svg';
import {ReactComponent as CryptoIcon} from '@assets/icons/crypto.svg';
import {ReactComponent as WithdrawIcon} from '@assets/icons/withdraw.svg';
import {ReactComponent as ExchangeIcon} from '@assets/icons/exchange.svg';
import {ReactComponent as StakingIcon} from '@assets/icons/staking.svg';
import {ReactComponent as NFTIcon} from '@assets/icons/nft.svg';
import {ReactComponent as AMLIcon} from '@assets/icons/aml.svg';
import {ReactComponent as CupIcon} from '@assets/icons/cup.svg';
import {ReactComponent as InviteIcon} from '@assets/icons/invite.svg';
import {ReactComponent as SupportIcon} from '@assets/icons/support.svg';

const ACTIONS = [
    {
        title: 'Para Yatır',
        icon: <DepositIcon />,
        primary: true,
		url: '/deposit'
    },
    {
        title: 'Robotlar',
        icon: <CryptoIcon />,
        primary: true,
		url: '/robots'
    },
    {
        title: 'Cüzdanım',
        icon: <WithdrawIcon />,
		url: '/wallet'
    },
    {
        title: 'İşlemlerim',
        icon: <StakingIcon />,
		url: '/transactions'
    },
    {
        title: 'Piyasalar',
        icon: <ExchangeIcon />,
		url: '/trade'
    },
    {
        title: 'Traderlar',
        icon: <NFTIcon />,
		url: '/traders'
    }
];

export default ACTIONS