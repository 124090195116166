import { useParams } from 'react-router-dom';
import AppLayout from '@components/AppLayout';
import PageHeader from '@components/PageHeader';
import PositionDetail from '@widgets/m/PositionDetail';

const SymbolDetail = () => {
	let { id } = useParams();
    return (
        <AppLayout>
            <PageHeader title={'Pozisyon Detayı'}/>
			<PositionDetail id={ id } />
        </AppLayout>
    )
}

export default SymbolDetail

