import React, { useState, useEffect, useContext  } from 'react';

import { fetchTraderPositionData, fetchTraderHistoryData, fetchTraderCommentsData } from '@services/apiService';
import { tradersContext } from '@contexts/tradersContext';
import {useModal} from '@contexts/modalContext';
import { useAuth } from '@contexts/AuthContext';
import useDraggableScrollContainer from '@hooks/useDraggableScrollContainer';
import { ScrollHorizontal, Currency } from './style';
import Spring from '@components/Spring';
import styles from './style.module.scss';
import TraderPositionList from '@components/m/TraderPositionList';
import TraderClosedList from '@components/m/TraderClosedList';

const tabs = [
    { value: 'position', label: 'Pozisyon' },
    { value: 'history', label: 'Geçmiş İşlemler' },
    { value: 'about', label: 'Hakkında' },
    { value: 'comment', label: 'Yorumlar' }
];

const PositionContent = ({ data }) => (
    <>
		<div className="flex-1">
			<div className="d-flex align-items-center justify-content-between" style={{padding: '0px 12px 0px 12px'}}>
                <div>Sembol</div>
                <div style={{paddingLeft: '35px'}}>Alış</div>
				<div>K/Z</div>
            </div>
        </div>
        <div className="track d-flex flex-column g-8" style={{margin: '2px 10px'}}>

			{data && data.length > 0 ? (
				data.map(position => (
					<TraderPositionList key={position.id} item={position} index={0} />
				))
			) : (
				<div style={{minHeight:'200px', marginTop:'30px', textAlign: 'center'}}>Pozisyon bulunamadı.</div>
			)}

        </div>
    </>
);


const HistoryContent = ({data}) => (
	<>
		<div className="flex-1">
			<div className="d-flex align-items-center justify-content-between" style={{padding: '0px 12px 0px 12px'}}>
				<div>Sembol</div>
				<div style={{paddingLeft: '35px'}}>Alış</div>
				<div>Kapanış</div>
				<div>K/Z</div>
			</div>
		</div>
        <div className="track d-flex flex-column g-8" style={{margin: '2px 10px'}}>
			{data && data.length > 0 ? (
				data.map(position => (
					<TraderClosedList key={position.id} item={position} index={0} />
				))
			) : (
				<div style={{minHeight:'200px', marginTop:'30px', textAlign: 'center'}}>Geçmiş Pozisyon bulunamadı.</div>
			)}
        </div>
	</>
);

const AboutContent = ({trader}) => (
	<div className={styles.container}>
		<div className="d-flex justify-content-between border-bottom pb-8">
			<span className="text-light">Tüm ROI:</span>
			<span className="text-header">{trader.allROI}</span>
		</div>
		<div className="d-flex justify-content-between border-bottom pb-8">
			<span className="text-light">Bu Ay ROI:</span>
			<span className="text-header">{trader.currentROI}</span>
		</div>
		<div className="d-flex justify-content-between border-bottom pb-8">
			<span className="text-light">Önceki Ay ROI:</span>
			<span className="text-header">{trader.beforeROI}</span>
		</div>
		<div className="d-flex justify-content-between border-bottom pb-8">
			<span className="text-light">Pozisyonlar:</span>
			<span className="text-header">{trader.profitNegative}</span>
		</div>
		<div className="d-flex justify-content-between border-bottom pb-8">
			<span className="text-light">Karla Kapanan İşlemler:</span>
			<span className="text-header">{trader.profitPositive}</span>
		</div>
		<div className="d-flex justify-content-between border-bottom pb-8">
			<span className="text-light">Zararla Kapanan İşlemler:</span>
			<span className="text-header">{trader.profitNegative}</span>
		</div>

	</div>
);

const CommentContent = () => (
    <div>
        <div style={{minHeight:'200px', marginTop:'30px', textAlign: 'center'}}>Henüz Bir Yorum Yok.</div>
    </div>
);



const TraderItem = ({ traderId }) => {
	
    const [selectedTab, setselectedTab] = useState(tabs[0].value);
    const [positionData, setPositionData] = useState(null);
    const [historyData, setHistoryData] = useState(null);
    const [commentsData, setCommentsData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
	const {handleOpen} = useModal();
	const { authToken } = useAuth();
    const { containerRef, isDragging } = useDraggableScrollContainer();
    const { traders } = useContext(tradersContext);
	
	const trader = traders.find(trader => {
		return trader._id === traderId;
	});

	const handleFollow = () => {
		handleOpen('payment', { trader, actionType: 'new' });
	};

	const handleEditFollow = () => {
		handleOpen('payment', { trader, actionType: 'edit' });
	};

    useEffect(() => {
        if (selectedTab === 'position') {
            setIsLoading(true);
            fetchTraderPositionData(trader.traderId, authToken).then(data => {
                setPositionData(data);
                setIsLoading(false);
            });
        }
    }, [trader.traderId, selectedTab, authToken]);

    const handleTabChange = (tab) => {
        setselectedTab(tab);
        if (tab === 'history') {
            setIsLoading(true);
            fetchTraderHistoryData(trader.traderId, authToken).then(data => {
                setHistoryData(data);
                setIsLoading(false);
            });
        } else if (tab === 'comment') {
            setIsLoading(true);
            fetchTraderCommentsData(trader.traderId, authToken).then(data => {
                setCommentsData(data);
                setIsLoading(false);
            });
        }
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'position':
                return isLoading ? 
				<>
				<div className="flex-1">
					<div className="d-flex align-items-center justify-content-between" style={{padding: '0px 25px 0px 25px'}}>
						<div>Sembol</div>
						<div style={{paddingLeft: '35px'}}>Alış</div>
						<div>Anlık</div>
						<div>K/Z</div>
					</div>
				</div>
				<div style={{textAlign: 'center', paddingTop: '25px', paddingBottom: '25px' }}>
				Veri Yükleniyor..
				</div>
				</>
				: <PositionContent data={positionData} />;
            case 'history':
                return isLoading ? 
				<>
				<div className="flex-1">
					<div className="d-flex align-items-center justify-content-between" style={{padding: '0px 25px 0px 25px'}}>
						<div>Sembol</div>
						<div style={{paddingLeft: '35px'}}>Alış</div>
						<div>Kapanış</div>
						<div>K/Z</div>
					</div>
				</div>
				<div style={{textAlign: 'center', paddingTop: '25px', paddingBottom: '25px' }}>
				Veri Yükleniyor..
				</div>
				</>
				: <HistoryContent data={historyData} />;
            case 'about':
                return <AboutContent trader={trader} />;
            case 'comment':
                return isLoading ? 
				<>
				<div style={{textAlign: 'center', paddingTop: '25px', paddingBottom: '25px' }}>
				Veri Yükleniyor..
				</div>
				</>
				: <CommentContent data={commentsData} />;
            default:
                return null;
        }
    };
	

    return (
        <Spring className='card' type="fade">
			<div className={styles.traderItem}>
				<div className={styles.traderImage}>
					<img src={trader.imageUrl} alt='' style={{borderRadius: '50%'}}/>
				</div>
				<div className="d-flex flex-column">
					<h3 className="text-overflow">{trader.name}</h3>
					<span>{trader.followCount} Takipçi</span>
				</div>
            </div>
			<div className="d-flex flex-column g-8 border-bottom">
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 15px' }}>
					{trader.isFollow ? (
						<button className="btn btn--pill btn--invert boxBorder" style={{width:'100%'}} onClick={handleEditFollow}>
						  Takip Ediliyor
						</button>
					) : (
						<button className="btn btn--pill btn--invert boxBorder" style={{width:'100%'}} onClick={handleFollow}>
						  Takip Et
						</button>
					)}
					
				</div>
				<div className="d-flex justify-content-between" style={{margin: '0px 15px 17px 15px'}}>
					<div className={`${styles.box} boxBorder`} style={{ flex: 1, textAlign: 'center' }}>
						<label>Mevcut Ay ROI</label>
						<div className={styles.boxText}>
							<span className="h3">{trader.currentROI}</span>
						</div>
					</div>
					<div className={`${styles.box} boxBorder`} style={{ flex: 1, textAlign: 'center' }}>
						<label>Önceki Ay ROI</label>
						<div className={styles.boxText}>
							<span className="h3">{trader.beforeROI}</span>
						</div>
					</div>
					<div className={`${styles.box} boxBorder`} style={{ flex: 1, textAlign: 'center' }}>
						<label>Pozisyon</label>
						<div className={styles.boxText}>
							<span className="h3">{trader.beforeROI}</span>
						</div>
					</div>
				</div>
			</div>
            <ScrollHorizontal className={`scroll-container ${isDragging ? 'isDragging' : ''}`} ref={containerRef}>
                {tabs.map(tab => (
                    <Currency
                        className={selectedTab === tab.value ? 'active' : ''}
                        onClick={() => handleTabChange(tab.value)}
                        key={tab.value}
                    >
                        <span className="h4" style={{ color: selectedTab === tab.value ? 'var(--widget-light)' : '' }}>{tab.label}</span>
                    </Currency>
                ))}
            </ScrollHorizontal>
            {renderTabContent()}
        </Spring>
    );
};

export default TraderItem;
