// hooks
import React, { useState, Suspense, useContext } from 'react';

import { tradersContext } from '@contexts/tradersContext'; 
import { useNavigate } from 'react-router-dom';

import Spring from '@components/Spring';
import TraderItem from '@components/m/TraderItem';

const Trade = () => {
	const [searchQuery, setSearchQuery] = useState('');
	const navigate = useNavigate();
	const { traders } = useContext(tradersContext);
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const filteredTraders = traders?.filter(trader => 
        trader.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
	
    return (
        <Spring>
            <div className="widget_container">
                <div className="widget_body card g-0">
						<input 
							type="text"
							className="field-input"
							placeholder="Ara..."
							value={searchQuery}
							onChange={handleSearchChange}
						/>
						<div className="track card" style={{ width: '100%', marginTop:'15px'}}>
							{filteredTraders.map((trader, i) => (
								<TraderItem key={trader._id} trader={trader} i={i} onClick={() => navigate(`/trader/${trader._id}`)}>
									{trader.name}
								</TraderItem>
							))}
						</div>
                        <Suspense fallback={<div className="suspense-text">Loading...</div>}>
                            {/* Dinamik içerik yüklenebilir */}
                        </Suspense>
                    
                </div>
            </div>
        </Spring>
    );
};

export default Trade;


//											