import dayjs from 'dayjs';

const notifications = [
    {
        id: 'notification-1',
        title: 'Bildirim Yok',
        text: `Henüz Bir Bildiriminiz Yok.`,
        read: false,
        date: dayjs(),
    }
];

export default notifications