import React from 'react';
import { useWindowSize } from 'react-use';
import { useState, useEffect } from 'react';
import { useAuth } from '@contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';

//
import Stocks from '@widgets/Stocks';


import TradeDetail from '@widgets/TradeDetail';
import MQuickActions from '@widgets/m/QuickActions';
import Graph from '@widgets/Graph';
import Positions from '@widgets/Positions';
import Footer from '@widgets/Footer';
import Traders from '@widgets/m/slideTraders';
import Banner from '@widgets/m/Banner';

const Dashboard = () => {
	const { authToken } = useAuth();
    const { width } = useWindowSize();
    const isMobile = width < 990;
    const [selectedSymbol, setSelectedSymbol] = useState('XAUUSD');
	const navigate = useNavigate();
	
    useEffect(() => {
        if (!authToken) {
            navigate('/sign-in');
        }
    }, [authToken, navigate]);
	
    const mobileWidgets = {

    };

    const desktopWidgets = {
		
		stocks: <Stocks onSymbolClick={setSelectedSymbol} />,
		graph: <Graph symbol={selectedSymbol} />,
		withdraw: <TradeDetail symbol={selectedSymbol}/>,
		positions: <Positions/>,
        
    };

    return (
        <AppLayout>
            <PageHeader title="Panel" variant="dashboard"/>
			{isMobile && <Traders/>}
			{isMobile && <Banner/>}
			{isMobile && <MQuickActions/>}
            <AppGrid id="dashboard" widgets={isMobile ? mobileWidgets : desktopWidgets}/>
			{!isMobile && <Footer/>}
        </AppLayout>
    );
};

export default Dashboard;