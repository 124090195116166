import React, { useEffect, useState } from 'react';
import Spring from './Spring';

const StockItem = ({ currency, ask, bid, i, className }) => {
    const iconPath = `../../assets/symbols/${currency.toLowerCase()}.png`;
    const [prevAsk, setPrevAsk] = useState(ask);
    const [prevBid, setPrevBid] = useState(bid);
    const [askChangeClass, setAskChangeClass] = useState('');
    const [bidChangeClass, setBidChangeClass] = useState('');

    useEffect(() => {
        if (ask !== prevAsk) {
            setAskChangeClass(ask > prevAsk ? 'price-up' : 'price-down');
            const timer = setTimeout(() => {
                setAskChangeClass('');
            }, 1000);
            setPrevAsk(ask);
            return () => clearTimeout(timer);
        }
    }, [ask, prevAsk]);

    useEffect(() => {
        if (bid !== prevBid) {
            setBidChangeClass(bid > prevBid ? 'price-up' : 'price-down');
            const timer = setTimeout(() => {
                setBidChangeClass('');
            }, 1000);
            setPrevBid(bid);
            return () => clearTimeout(timer);
        }
    }, [bid, prevBid]);

    return (
        <Spring className={`crypto-item border-bottom ${className ? className : ''}`} index={1} type="list">
            <div className="crypto-icon">
                <img src={iconPath} alt={currency} />
            </div>
            <div className="flex-1">
                <div className="d-flex align-items-center justify-content-between">
                    <h3>{currency}</h3>
                    <span className={`h6 ${askChangeClass}`}>{ask}</span>
                    <span className={`h6 ${bidChangeClass}`}>{bid}</span>
                </div>
            </div>
        </Spring>
    );
}

export default StockItem;
