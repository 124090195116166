// utils
import PropTypes from 'prop-types';

const LabelField = ({ id, value, label, placeholder, customInput, ...props }) => {
    return (
        <div className="field-wrapper">
            <label className="field-label" htmlFor={id}>{label}</label>
            {
                customInput ?
                    customInput : <input className="field-input" id={id} value={value} placeholder={placeholder} {...props} />
            }
        </div>
    )
}

LabelField.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    customInput: PropTypes.element,
}

export default LabelField