import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppLayout from '@components/AppLayout';
import PageHeader from '@components/PageHeader';
import TradeDetail from '@widgets/m/TradeDetail';

const SymbolDetail = () => {
    let { symbol } = useParams();
    const location = useLocation();
    const { action } = location.state || {};
	
    return (
        <AppLayout>
            <PageHeader title={symbol}/>
            <TradeDetail symbol={symbol} action={action}/>
        </AppLayout>
    );
};

export default SymbolDetail;
