import React, { createContext, useState, useEffect } from 'react';
import { fetchUserProfile } from '@services/apiService';
import { useAuth } from '@contexts/AuthContext';
import { useWebSocket } from '@contexts/WebSocketContext';

export const userContext = createContext();

export const UserProvider = ({ children }) => {
	
	const { authToken } = useAuth();
	const webSocket = useWebSocket();
  
	const [userProfile, setUserProfile] = useState(() => {
		const savedProfile = localStorage.getItem('userProfile');
		return savedProfile ? JSON.parse(savedProfile) : null;
	});

	useEffect(() => {
		const loadUserProfile = async () => {
		  if (authToken) {
			const profileData = await fetchUserProfile(authToken);
			localStorage.setItem('userProfile', JSON.stringify(profileData));
			setUserProfile(profileData);
		  } else {
			localStorage.removeItem('userProfile');
			setUserProfile(null);
		  }
		};

		loadUserProfile();
	}, [authToken]);




	useEffect(() => {
		if (!webSocket) return;

		const handleMarketUpdate = (event) => {
			const data = JSON.parse(event.data);
			if ('blc' in data || 'crd' in data || 'mrg' in data || 'ass' in data || 'fm' in data || 'pct' in data || 'allp' in data) {
				setUserProfile(prevProfile => ({
					...prevProfile,
					...('blc' in data && {balance: data.blc}),
					...('crd' in data && {credit: data.crd}),
					...('mrg' in data && {margin: data.mrg}),
					...('ass' in data && {assets: data.ass}),
					...('fm' in data && {marginFree: data.fm}),
					...('pct' in data && {marginPct: data.pct}),
					...('allp' in data && {floating: data.allp})
				}));
			}
		};
		webSocket.addEventListener('message', handleMarketUpdate);

		return () => {
			if (webSocket) {
				webSocket.removeEventListener('message', handleMarketUpdate);
			}
		};
	}, [webSocket]);


  return (
    <userContext.Provider value={userProfile}>
      {children}
    </userContext.Provider>
  );
};
