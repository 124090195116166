import React, { useState, Suspense } from 'react';
import useDraggableScrollContainer from '@hooks/useDraggableScrollContainer';
import Spring from '@components/Spring';
import StocksTab from '@widgets/m/StocksTab';
import StocksList from '@widgets/m/StocksList';

const Trade = () => {
    const { containerRef, isDragging } = useDraggableScrollContainer();
    const [selectedCategory, setSelectedCategory] = useState('emtia');

    return (
        <Spring type="list">
            <div className="widget_container">
                <div className="widget_body g-0">
                    <StocksTab
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        isDragging={isDragging}
                        containerRef={containerRef}
                    />
                    <StocksList 
                        selectedCategory={selectedCategory} 
                    />
                    <Suspense fallback={<div className="suspense-text">Loading...</div>}>
                        {/* Dynamic content can be loaded here */}
                    </Suspense>
                </div>
            </div>
        </Spring>
    );
};

export default Trade;
