// styling
import styles from './style.module.scss';

// components
import Spring from '@components/Spring';
import LazyImage from '@components/LazyImage';

// hooks
import { useNavigate } from 'react-router-dom';
import {useContext} from 'react';
import useDraggableScrollContainer from '@hooks/useDraggableScrollContainer';
import { tradersContext } from '@contexts/tradersContext'; 

const TopTraders = () => {
    const {containerRef, isDragging} = useDraggableScrollContainer();
	const navigate = useNavigate();
	const { traders } = useContext(tradersContext);
	if (!traders) {
		return;
	}
    return (
        <Spring type="fade">
            <div className="widget_body card bordered p-0 h-100">
                <div className={styles.header}>
					<div className="d-flex" style={{justifyContent: 'space-between'}}>
						<h2>Traderlar</h2><h3 style={{paddingTop:'5px'}} onClick={() => navigate('/traders')}>Tümü</h3>
					</div>
                </div>
                <div className={`${styles.container} scroll-container ${isDragging ? 'isDragging' : ''}`}
                     ref={containerRef}>
                    {
                        traders.map((trader, index) => (
                            <Spring key={trader.name} index={index} type="slide">
                                <div className={styles.wrapper} onClick={() => navigate('/trader/' + trader._id)}>
                                    <div>
                                        <LazyImage className={styles.avatar} src={trader.imageUrl} alt={trader.name} style={{borderRadius: '50%',border: 'solid red'}}/>
                                    </div>
                                    <h4 className={styles.text}>{trader.name}</h4>
                                    <span style={{color:'#32ff32'}}>{trader.currentROI}</span>
                                </div>
                            </Spring>
                        ))
                    }
                </div>
            </div>
        </Spring>
    )
}

export default TopTraders