// styling
import styles from './style.module.scss';

// components
import MarketItem from '@components/m/MarketItem';
import Spring from '@components/Spring';
import ScrollContainer from '@components/ScrollContainer';

// hooks
import React, { useEffect, useState, useContext } from 'react';
import { useAuth } from '@contexts/AuthContext';
import { userContext } from '@contexts/userContext';
import { ordersContext } from '@contexts/ordersContext';
import { useThemeProvider } from '@contexts/themeContext';

import useMeasure from 'react-use-measure';

const StandaloneWrapper = ({ children, height }) => {
    return (
        <ScrollContainer height={height}>
            <div className="track d-flex flex-column g-8" style={{paddingBottom:'200px'}}>
                {children}
            </div>
        </ScrollContainer>
    );
}

const BuiltInWrapper = ({ children }) => {
    return (
        <div className="d-flex flex-column g-8" style={{ paddingBottom: '80px' }}>
            {children}
        </div>
    );
}

const Market = ({ standalone = true }) => {
    const { theme } = useThemeProvider();
    const [filter, setFilter] = useState('position');
    const [ref, { height }] = useMeasure();
    const userProfile = useContext(userContext);
    const { positions = [], waitings = [], closed = [], refreshOrders } = useContext(ordersContext);
    const { authToken } = useAuth();

    useEffect(() => {
        if (!authToken) return;
        refreshOrders();
    }, [authToken, refreshOrders]);


    const Wrapper = standalone ? StandaloneWrapper : BuiltInWrapper;
    const wrapperProps = standalone ? { height } : {};

	const FilterTabs = React.memo(({ filter, setFilter }) => {
		const tabs = [
			{ value: 'position', label: 'Pozisyonlar' },
			{ value: 'waiting', label: 'Bekleyenler' },
			{ value: 'closed', label: 'Kapananlar' }
		];

		return (
			<>
				{tabs.map(tab => (
					<button key={tab.value} className={filter === tab.value ? 'active' : ''} onClick={() => setFilter(tab.value)}>
						{tab.label}
					</button>
				))}
			</>
		);
	});



    let filteredData = [];
    switch (filter) {
        case 'position':
            filteredData = positions || [];
            break;
        case 'waiting':
            filteredData = waitings || [];
            break;
        case 'closed':
            filteredData = closed || [];
            break;
        default:
            filteredData = [];
    }

    return (
        <Spring type="fade" className={styles.widgetP} style={{ margin: '20px' }}>
            <div className="card" style={{ padding: '10px' }}>
                <div className="card card--abstract d-flex flex-column g-8">
                    <div className="d-flex justify-content-between text-11">
                        <span className="text-white">Bakiye</span>
                        <span className="text-white">Kredi</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between text-white">
                        <div className="d-flex align-items-center g-8 lh-1">
                            <span className="text-large">$ {userProfile.balance}</span>
                        </div>
                        <span className="text-600">$ {userProfile.credit}</span>
                    </div>
                </div>
                <div className="d-flex flex-column g-8">
                    <div className="d-flex justify-content-between">
                        <div className={`${styles.box} boxBorder`} style={{ flex: 1, textAlign: 'center' }}>
                            <label>VARLIK</label>
                            <div className={styles.boxText}>
                                <span className="h3">${userProfile.assets}</span>
                            </div>
                        </div>
                        <div className={`${styles.box} boxBorder`} style={{ flex: 1, textAlign: 'center' }}>
                            <label>TEMİNAT</label>
                            <div className={styles.boxText}>
                                <span className="h3">${userProfile.margin}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column g-8">
                    <div className="d-flex justify-content-between">
                        <div className={`${styles.box} boxBorder`} style={{ flex: 1, textAlign: 'center' }}>
                            <label>SERBEST TEMİNAT</label>
                            <div className={styles.boxText}>
                                <span className="h3">${userProfile.marginFree}</span>
                            </div>
                        </div>
                        <div className={`${styles.box} boxBorder`} style={{ flex: 1, textAlign: 'center' }}>
                            <label>TEMİNAT SEVİYESİ</label>
                            <div className={styles.boxText}>
                                <span className="h3">%{userProfile.marginPct}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" g-8">
                    <div className={`${styles.box} boxBorder`} style={{ flex: 1, textAlign: 'center' }}>
                        <label>ANLIK KAR / ZARAR</label>
                        <div className={styles.boxText}>
                            <span className="h3">{userProfile.floating} USD</span>
                        </div>
                    </div>
                </div>
            </div>


			
			<div style={{ paddingTop: '20px' }} className={`d-flex flex-column g-24 ${standalone ? 'pb-16' : 'pb-8'}`} ref={ref}>
                {

                   standalone &&
                    <div className="d-flex flex-column g-16">
						<div className={`tab-list col-3 mb-0 ${theme === 'light' ? 'bordered' : ''}`}>
							<FilterTabs filter={filter} setFilter={setFilter} />
						</div>
                    </div>
                }
            </div>
			
			
			
            <div className="">
                <div style={{ marginBottom: '15px' }}>
                    <div>
                        {filter === 'position' && (
                            <div className="flex-1">
                                <div className="d-flex align-items-center justify-content-between" style={{ padding: '0px 12px 0px 12px' }}>
                                    <div>Sembol</div>
                                    <div style={{ paddingLeft: '35px' }}>Alış</div>
                                    <div>Anlık</div>
                                    <div>K/Z</div>
                                </div>
                            </div>
                        )}
                        {filter === 'waiting' && (
                            <div className="flex-1">
                                <div className="d-flex align-items-center justify-content-between" style={{ padding: '0px 12px 0px 12px' }}>
                                    <div>Sembol</div>
                                    <div style={{ paddingLeft: '55px' }}>Fiyat</div>
                                    <div>Miktar</div>
                                    <div>işlem</div>
                                </div>
                            </div>
                        )}
                        {filter === 'closed' && (
                            <div className="flex-1">
                                <div className="d-flex align-items-center justify-content-between" style={{ padding: '0px 12px 0px 12px' }}>
                                    <div>Sembol</div>
                                    <div style={{ paddingLeft: '35px' }}>Alış</div>
                                    <div>Kapanış</div>
                                    <div>K/Z</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Wrapper {...wrapperProps}>
                {filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                        <MarketItem key={item.id || index} item={item} index={index} filter={filter} withBorder={!standalone} />
                    ))
                ) : (
                    <Spring>
                        <div style={{ textAlign: 'center', paddingTop: '50px' }} className={styles.message}>
                            Herhangi bir veri yok.
                        </div>
                    </Spring>
                )}
            </Wrapper>
        </Spring>
    );
}

export default Market;
