import styles from './style.module.scss';
import TopTraders from '@widgets/m/TopTraders';
import Spring from '@components/Spring';

import {useThemeProvider} from '@contexts/themeContext';

const Explore = () => {
    const {theme} = useThemeProvider();

    return (
        <Spring className="widget" type="fade">
            <div className={`${styles.wrapper} widget_body card ${theme === 'light' ? 'card--no-bg' : ''}`}>
                <TopTraders/>
            </div>
        </Spring>
    )
}

export default Explore