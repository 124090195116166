import styles from './style.module.scss';
import { Decimal } from 'decimal.js';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import LabelField from '@components/LabelField';
import LabelFieldCheckbox from '@components/m/LabelFieldCheckbox';
import MarginCalc from '@components/MarginCalc';
import RangeSlider from '@components/RangeSlider';
import Spring from '@components/Spring';

import { useThemeProvider } from '@contexts/themeContext';
import { useAuth } from '@contexts/AuthContext';
import { userContext } from '@contexts/userContext';
import { symbolsContext } from '@contexts/symbolsContext';
import { ordersContext } from '@contexts/ordersContext';
import { fetchCreateOrder } from '@services/apiService';

import graphLight from '@assets/icons/graphLight.svg';
import graphDark from '@assets/icons/graphDark.svg';

const TradeDetail = ({ symbol, action }) => {
    const { theme } = useThemeProvider();
    const [marketLimit, setMarketLimit] = useState(null);
    const [stopLoss, setStopLoss] = useState(null);
    const [takeProfit, setTakeProfit] = useState(null);
    const { authToken } = useAuth();
    const { symbols, livePrice } = useContext(symbolsContext);
    const [symbolData, setSymbolData] = useState(null);
    const [calcSymbolData, setCalcSymbolData] = useState(null);
    const [amount, setAmount] = useState("0.01");
    const [isOpen, setIsOpen] = useState(null);
    const [oTime, setOTime] = useState(null);
    const [activeTab, setActiveTab] = useState(action ? action : 'Buy');
    const userProfile = useContext(userContext);
    const { refreshOrders } = useContext(ordersContext);
    const navigate = useNavigate();
	
    useEffect(() => {
        if (symbols) {
            const foundSymbol = symbols.find(s => s.symbol === symbol);
            if (foundSymbol) {
                setSymbolData(foundSymbol);
                setIsOpen(foundSymbol.isOpen);
                setOTime(foundSymbol.oTime);
                if ([1, 2].includes(foundSymbol.calc)) {
                    let calcSymbol = null;
                    if (foundSymbol.calc === 1) {
                        calcSymbol = 'USD' + foundSymbol.currencyProfit;
                    } else if (foundSymbol.calc === 2) {
                        calcSymbol = foundSymbol.currencyProfit + 'USD';
                    }
                    if (calcSymbol) {
                        const foundCalcSymbol = symbols.find(s => s.symbol === calcSymbol);
                        if (foundCalcSymbol) {
                            setCalcSymbolData(foundCalcSymbol);
                        }
                    }
                }
            } else {
                navigate('/');
            }
        }
    }, [symbol, symbols, navigate]);

    if (!symbolData || (symbolData.calc !== 0 && !calcSymbolData)) {
        return <div>Veri Bulunamadı</div>;
    }

    const minAmount = new Decimal(symbolData.min);
    const maxAmount = new Decimal(symbolData.max);

    const increaseAmount = () => {
        setAmount(prevAmount => {
            const updatedAmount = new Decimal(prevAmount).plus(minAmount);
            return updatedAmount.lte(maxAmount) ? updatedAmount.toFixed(2) : maxAmount.toFixed(2);
        });
    };

    const decreaseAmount = () => {
        setAmount(prevAmount => {
            const updatedAmount = new Decimal(prevAmount).minus(minAmount);
            return updatedAmount.gte(minAmount) ? updatedAmount.toFixed(2) : minAmount.toFixed(2);
        });
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setAmount(minAmount.toFixed(2));
            return;
        }
        let safeValue = new Decimal(value);
        if (safeValue.isNaN()) {
            safeValue = new Decimal(minAmount);
        }
        const clampedValue = Decimal.max(minAmount, Decimal.min(safeValue, maxAmount)).toFixed(2);
        setAmount(clampedValue);
    };

    const handleCreateOrder = async () => {
        try {
            const formData = {
                symbol: symbolData.symbol,
                type: activeTab,
                amount: amount,
                marketLimit: marketLimit,
                stopLoss: stopLoss,
                takeProfit: takeProfit,
            };
            const response = await fetchCreateOrder(formData, authToken);
            if (response.status) {
                await refreshOrders();
                navigate('/transactions');
                toast.success('Başarıyla Tamamlandı.', {
                    autoClose: 3000
                });
            } else {
                toast.error(response.message, {
                    autoClose: 3000
                });
            }
        } catch (error) {
            const errorMessage = error.message || 'Bir hata oluştu.';
            toast.error(errorMessage, {
                autoClose: 5000
            });
        }
    };

    const iconPath = `../../assets/symbols/${symbolData.symbol.toLowerCase()}.png`;



	const DateTimeDisplay = ({ oTime }) => {
		let formattedOpenTime = '';

		if (oTime) {
			const openDate = new Date(oTime);
			const localDate = new Date(openDate.getTime() + (openDate.getTimezoneOffset() * 60000));
			const openDay = new Intl.DateTimeFormat('tr-TR', { weekday: 'long' }).format(localDate);
			const timePart = oTime.split('T')[1];
			const openTime = timePart ? timePart.split(':').slice(0, 2).join(':') : '00:00';
			formattedOpenTime = `${openDay}, ${openTime}`;
		}

		return (
			<div>
				{formattedOpenTime && <span>{formattedOpenTime}</span>}
			</div>
		);
	};


    const handleButtonClick = () => {
        if (isOpen) {
            handleCreateOrder();
        }
    };

    let closedDiv = null;
    if (!isOpen) {
        closedDiv = (
            <div style={{border: '1px solid var(--border-dark)', padding: '6px', marginBottom: '10px', borderRadius: '12px'}}>
                <div style={{textAlign: 'center', paddingBottom: '2px'}}> Piyasa Açılış Zamanı: </div>
                <div style={{textAlign: 'center'}}><DateTimeDisplay oTime={oTime} /></div>
            </div>
        );
    }

    return (
        <Spring className="widget">
            <div className="widget_body card" style={{ marginBottom: '150px' }}>
                <div className="d-flex flex-column g-5">
                    <div className={styles.traderItem}>
                        <div className={styles.traderImage}>
                            <img src={iconPath} alt='' />
                        </div>
                        <div className="d-flex flex-column">
                            <h2 className="text-overflow">{symbolData.symbol}</h2>
                            <span>{symbolData.desc}</span>
                        </div>
                        <div className="flex-1">
                            <div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                {symbolData.chart && (
                                    <>
                                        <img
                                            className="symbol"
                                            src={theme === 'light' ? graphLight : graphDark}
                                            alt="icon"
                                            onClick={() => {
                                                navigate(`/chart/${symbolData.symbol}`);
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
					
					{closedDiv}
					
                    <div className={`${styles.tabList} btn-group btn-block base-TabsList-root base-TabsList-horizontal col-2 bordered`}>
                        <span
                            className={`${styles.sellTab} trade-tab ${activeTab === 'Sell' ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab('Sell');
                            }}
                            style={{ cursor: 'pointer', background: activeTab === 'Sell' ? 'rgb(242, 54, 69)' : '#b8b8b800', color: activeTab === 'Sell' ? 'white' : 'var(--text-dark)' }}>
                            Satış <span style={{ float: 'right' }}>
								{isOpen ? (
									<span style={{ float: 'left' }}>
										{livePrice[symbolData.symbol].ask}
									</span>
								) : (
									<span style={{ float: 'left' }}>
										{symbolData.ask}
									</span>
								)}							
							</span>
                        </span>
                        <span
                            className={`${styles.buyTab} trade-tab ${activeTab === 'Buy' ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab('Buy');
                            }}
                            style={{ cursor: 'pointer', background: activeTab === 'Buy' ? 'rgb(9, 183, 109)' : '#b8b8b800', color: activeTab === 'Buy' ? 'white' : 'var(--text-dark)' }}>
							{isOpen ? (
								<span style={{ float: 'left' }}>
									{livePrice[symbolData.symbol].bid}
								</span>
							) : (
								<span style={{ float: 'left' }}>
									{symbolData.bid}
								</span>
							)} Alış
							
                        </span>
                    </div>
                    <div className="d-flex g-5" style={{ alignItems: 'flex-end' }}>
                        <button
                            className={`btn ${theme === 'light' ? 'btn--invert' : ''}`}
                            onClick={decreaseAmount}
                            style={{ width: '35px' }}
                            disabled={amount <= minAmount}>
                            -
                        </button>
                        <div style={{ flex: 1, margin: '0 10px' }}>
                            <LabelField style={{ textAlign: 'center' }}
                                id="amount"
                                placeholder="Min. amount: 0.01"
                                value={amount}
                                onChange={handleChange} />
                        </div>
                        <button
                            className={`btn ${theme === 'light' ? 'btn--invert' : ''}`}
                            onClick={increaseAmount}
                            style={{ width: '35px' }}>
                            +
                        </button>
                    </div>
                    <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', padding: '15px 5px 0px 5px' }}>
                        <span>Min: {symbolData.min}</span>
                        <span>Max: {symbolData.max}</span>
                    </div>
                    <RangeSlider value={amount} onChange={handleChange} min={minAmount} max={maxAmount} step="0.01" />
                    <div>
                        <LabelFieldCheckbox
                            minAmount={minAmount}
                            maxAmount={maxAmount}
                            sellPrice={livePrice[symbolData.symbol].bid}
                            buyPrice={livePrice[symbolData.symbol].ask}
                            tab={activeTab}
                            id="deneme"
                            setMarketLimit={setMarketLimit}
                            setStopLoss={setStopLoss}
                            setTakeProfit={setTakeProfit}
                        />
                    </div>
                </div>
                <div className="d-flex flex-column g-8">
                    <MarginCalc
                        freeMargin={userProfile.marginFree}
                        calcSymbol={calcSymbolData || {}}
                        tab={activeTab}
                        symbol={symbolData}
                        ask={livePrice[symbolData.symbol].ask}
                        bid={livePrice[symbolData.symbol].bid}
                        amount={amount}
                        amountcurrency='USD'
                    />
                </div>
				
                {activeTab === 'Buy' && (
                    <button
                        className={`${styles.btnBuy} btn ${theme === 'dark' ? 'btn--invert' : ''}`}
                        onClick={handleButtonClick}
                        style={{ backgroundColor: isOpen ? '' : 'gray' }}
                        disabled={!isOpen}>
                        {isOpen ? 'Buy' : 'Piyasa Kapalı'}
                    </button>
                )}
                {activeTab === 'Sell' && (
                    <button
                        className={`${styles.btnSell} btn ${theme === 'light' ? 'btn--invert' : ''}`}
                        onClick={handleButtonClick}
                        style={{ backgroundColor: isOpen ? '' : 'gray' }}
                        disabled={!isOpen}>
                        {isOpen ? 'Sell' : 'Piyasa Kapalı'}
                    </button>
                )}
            </div>
        </Spring>
    );
}

export default TradeDetail;
