import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { symbolsContext } from '@contexts/symbolsContext';
import MStockItem from '@components/m/StockItems';
import ScrollContainer from '@components/ScrollContainer';
import useMeasure from 'react-use-measure';

const StandaloneWrapper = ({ children, height }) => {
    return (
        <ScrollContainer height={height}>
            <div className="track d-flex flex-column g-8 body-bg" style={{ paddingBottom: '200px' }}>
                {children}
            </div>
        </ScrollContainer>
    );
}

const BuiltInWrapper = ({ children }) => {
    return (
        <div className="d-flex flex-column g-8 body-bg" style={{ paddingBottom: '80px' }}>
            {children}
        </div>
    );
}

const StocksList = ({ selectedCategory, standalone = false }) => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { symbols, livePrice } = useContext(symbolsContext);
    const navigate = useNavigate();
    const [ref, { height }] = useMeasure();

    useEffect(() => {
        if (symbols && symbols.length > 0) {
            setData(symbols);
        }
    }, [symbols]);
	
    const Wrapper = standalone ? StandaloneWrapper : BuiltInWrapper;
    const wrapperProps = standalone ? { height } : {};

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleTradeAction = ({ action, currency }) => {
        navigate(`/order/${currency}`, { state: { action, currency } });
    };

    const filteredData = data.filter(
        (item) => item.catCode.toLowerCase() === selectedCategory && item.symbol.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <input
                type="text"
                className="field-input"
                placeholder="Ara..."
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ width: '100%' }}
            />
            <div className="track body-bg" style={{ width: '100%', marginTop: '10px', marginBottom: '100px' }}>
                <Wrapper {...wrapperProps}>
                    {filteredData.map((item, i) => (
                        <MStockItem
                            key={item.symbol}
                            currency={item.symbol}
                            ask={livePrice[item.symbol] ? livePrice[item.symbol].ask : 'N/A'}
                            bid={livePrice[item.symbol] ? livePrice[item.symbol].bid : 'N/A'}
                            i={i}
                            isOpen={item.isOpen}
                            oTime={item.oTime}
                            onClick={() => navigate(`/order/${item.symbol}`)}
                            onTradeAction={handleTradeAction}
                        />
                    ))}
                </Wrapper>
            </div>
        </>
    );
};

export default StocksList;
