// styling
import styles from './style.module.scss';

// components
import Star from '@ui/Star';
import NumLabel from '@ui/NumLabel';
import CurrencyItem from '@components/m/CurrencyItem';
import Spring from '@components/Spring';



// hooks
import {useDispatch} from 'react-redux';
import {useWindowSize} from 'react-use';

// actions
import {toggleFavorite} from '@features/crypto/cryptoSlice';

const MarketItem = ({item, index, filter, withBorder}) => {

    const {width} = useWindowSize();
    const dispatch = useDispatch();
	
    const {id, amount, type, symbol, price, profitPct} = item;

    const isCompact = width < 568 || (width >= 1440 && width < 1700) ||( width >= 1920 && width < 2000);
	const iconPath = `../../assets/symbols/${symbol.toLowerCase()}.png`;

	const formattedProfitPct = !isNaN(parseFloat(profitPct)) && isFinite(profitPct) ? parseFloat(profitPct).toFixed(2) : '0.00';

    return (
       <Spring type="list" index={index} >
			<div className={`card ${withBorder ? 'bordered' : ''}`} >
				<div>
					<>
						<div className={`${styles.container} ${withBorder ? 'bordered' : ''}`} type="list" index={index}>

							<div className="flex-1">
								<div className="d-flex align-items-center justify-content-between">
									<div className={styles.wrapper}>
										<CurrencyItem icon={null} name={symbol} label={amount} type={type} />
									</div>
									<span className="h6">{price}</span>
									<span className="h6"><NumLabel value={formattedProfitPct} /></span>
								</div>
							</div>
						</div>
					</>
				</div>
			</div>
		</Spring>
    )
}

export default MarketItem