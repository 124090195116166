import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLogout, fetchUserProfile } from '@services/apiService';
import useAuthInterceptor from '@hooks/useAuthInterceptor';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => localStorage.getItem('userToken'));
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();

  // useAuthInterceptor hook'unu kullan
  useAuthInterceptor(setAuthToken, setUserProfile, navigate);

  const login = async (token) => {
    setAuthToken(token);
    localStorage.setItem('userToken', token);
    const profile = await fetchUserProfile(token);
    setUserProfile(profile);
  };

  const logout = async () => {
    try {
      const token = localStorage.getItem('userToken');
      if (token) {
        await fetchLogout(token);
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setAuthToken(null);
      setUserProfile(null);
      localStorage.removeItem('userToken');
      navigate('/sign-in');
    }
  };

  useEffect(() => {
    if (authToken) {
      localStorage.setItem('userToken', authToken);
      fetchUserProfile(authToken).then(profile => setUserProfile(profile));
    } else {
      localStorage.removeItem('userToken');
      setUserProfile(null);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider value={{ authToken, login, logout, userProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
