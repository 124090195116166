// styling
import styles from './style.module.scss';
import React from 'react';
// utils
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';

const OperationTotal = ({ freeMargin, calcSymbol, amount, currency = 'USD', symbol }) => {
  const calculateResult = () => {

    if (!symbol) return "Data is missing";

    const { ask, bid, lvg, contractSize, calc, currencyProfit } = symbol;

    const amountDecimal = new Decimal(amount || 0);
    const dataLvg = new Decimal(lvg || 1);
    const dataCs = new Decimal(contractSize || 1);
    const priceAsk = new Decimal(ask || 0);
    const priceBid = new Decimal(bid || 0);

	const priceAverage = priceAsk.plus(priceBid).dividedBy(2);
	const firstResult = priceAverage.times(dataCs).times(amountDecimal).dividedBy(dataLvg);
	const percentageToAdd = firstResult.times(0.20);
	const newResult = firstResult.plus(percentageToAdd);

	let printResult;
	
	if (calc === 0) {
	  printResult = newResult.toFixed(2);
	} else if (calc === 1 || calc === 2) {
	  const profitSymbolBid = new Decimal(calcSymbol.bid);
	  const profitSymbolAsk = new Decimal(calcSymbol.ask);
	  const profitAverage = profitSymbolAsk.plus(profitSymbolBid).dividedBy(2);
	  const avgResult = newResult.dividedBy(profitAverage);
	  printResult = avgResult.toFixed(2);
	} else {
	  printResult = "Hesaplanamadı";
	}
	
    return printResult;
  };

  return (
    <div className={styles.container}>
      <div className="d-flex justify-content-between border-bottom pb-8">
        <span className="text-light">Serbest Teminat:</span>
        <span className="text-header">{freeMargin} {currency}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span className="text-light">Gerekli Teminat:</span>
        <span className="text-header">{calculateResult()} {currency}</span>
      </div>
    </div>
  );
};

OperationTotal.propTypes = {

};

export default OperationTotal;