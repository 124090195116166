import { useParams } from 'react-router-dom';
import AppLayout from '@components/AppLayout';
import PageHeader from '@components/PageHeader';
import ChartDetail from '@widgets/m/Chart';

const Chart = () => {
	let { symbol } = useParams();
    return (
        <AppLayout>
            <PageHeader title={symbol}/>
			<ChartDetail symbol={symbol} />
        </AppLayout>
    )
}

export default Chart

