import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import WalletOverview from '@widgets/WalletOverview';
import MTraders from '@widgets/m/Traders';
import Footer from '@widgets/Footer';
import { useWindowSize } from 'react-use';

const mobileWidgets = {

};

const desktopWidgets = {
    wallet: <WalletOverview/>,
    deposit: <MTraders/>,
};


const Traders = () => {
	const { width } = useWindowSize();
	const isMobile = width < 990;

	return (
			<AppLayout>
				<PageHeader title="Traderlar"/>
				{isMobile && <MTraders/>}
				<AppGrid id="wallet" widgets={isMobile ? mobileWidgets : desktopWidgets}/>
				{!isMobile && <Footer/>}
			</AppLayout>
	);

}

export default Traders