// RangeSlider.jsx
import React from 'react';

const RangeSlider = ({ value, onChange, min = "0.01", max = "25", step = "0.01" }) => {
  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        className="mt-3"
      />
    </div>
  );
};

export default RangeSlider;
