import React, { createContext, useState, useEffect, useCallback } from 'react';
import { fetchPositions, fetchWaitingOrders, fetchClosedOrders } from '@services/apiService';
import { useAuth } from '@contexts/AuthContext';
import { useWebSocket } from '@contexts/WebSocketContext';

export const ordersContext = createContext();

export const OrdersProvider = ({ children }) => {
  const { authToken } = useAuth();
  const webSocket = useWebSocket();
  const [positions, setPositions] = useState([]);
  const [waitings, setWaitings] = useState([]);
  const [closed, setClosed] = useState([]);

  const loadOrders = useCallback(async () => {
    if (!authToken) return;

    try {
      const [positionsData, waitingsData, closedData] = await Promise.all([
        fetchPositions(authToken),
        fetchWaitingOrders(authToken),
        fetchClosedOrders(authToken)
      ]);
      if (positionsData && positionsData.data) {
        setPositions(positionsData.data);
        localStorage.setItem('positions', JSON.stringify(positionsData.data));
      } else {
        localStorage.removeItem('positions');
        setPositions(null);
      }

      if (waitingsData && waitingsData.data) {
        setWaitings(waitingsData.data);
        localStorage.setItem('waitings', JSON.stringify(waitingsData.data));
      } else {
        localStorage.removeItem('waitings');
        setWaitings(null);
      }

      if (closedData && closedData.data) {
        setClosed(closedData.data);
        localStorage.setItem('closed', JSON.stringify(closedData.data));
      } else {
        localStorage.removeItem('closed');
        setClosed(null);
      }
    } catch (error) {
      console.error('Error loading orders:', error);
      localStorage.removeItem('positions');
      setPositions(null);
      localStorage.removeItem('waitings');
      setWaitings(null);
      localStorage.removeItem('closed');
      setClosed(null);
    }
  }, [authToken]);

  useEffect(() => {
    if (!webSocket) return;

    const handlePositionUpdate = (event) => {
      const data = JSON.parse(event.data);
      setPositions((prevPositions) =>
        prevPositions.map(position =>
          position.id === data.id
            ? { ...position, profit: data.profit, lastPrice: data.currentPrice }
            : position
        )
      );
    };

    webSocket.addEventListener('message', handlePositionUpdate);
    return () => {
      webSocket.removeEventListener('message', handlePositionUpdate);
    };
  }, [webSocket]);

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  return (
    <ordersContext.Provider value={{ positions, waitings, closed, refreshOrders: loadOrders }}>
      {children}
    </ordersContext.Provider>
  );
};
