import React from 'react';
import { Currency, ScrollHorizontal } from './style';

const catCode = [
    { value: 'emtia', label: 'Emtia' },
    { value: 'forex', label: 'Forex' },
    { value: 'indices', label: 'Endeks' },
    { value: 'bist', label: 'Bist' },
    { value: 'equities', label: 'Hisse' },
    { value: 'crypto', label: 'Kripto' },
];

const StocksTab = ({ selectedCategory, setSelectedCategory, isDragging, containerRef }) => (
    <ScrollHorizontal className={`scroll-container ${isDragging ? 'isDragging' : ''}`} ref={containerRef}>
        {catCode.map(category => (
            <Currency
                className={selectedCategory === category.value ? 'active' : ''}
                onClick={() => setSelectedCategory(category.value)}
                key={category.value}
            >
                <span className="h4" style={{ color: selectedCategory === category.value ? 'white' : '', padding: '25px'}}>
                    {category.label}
                </span>
            </Currency>
        ))}
    </ScrollHorizontal>
);

export default StocksTab;
