import styles from './style.module.scss';
import PropTypes from 'prop-types';

const PositionDetailList = ({ value, title }) => {
    return (
        <div className={styles.container}>
            <div className="d-flex justify-content-between">
                <span className="text-light">{title}:</span>
                <span className="text-header">{value}</span>
            </div>
        </div>
    );
};

PositionDetailList.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PositionDetailList;
