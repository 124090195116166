// styling
import styles from './style.module.scss';
import {NavLink} from 'react-router-dom';
// components
import Helmet from 'react-helmet';

// hooks
import {useWindowSize} from 'react-use';
import {useNavigate} from 'react-router-dom';
import {useSidebar} from '@contexts/sidebarContext';
import {useState} from 'react';
import {useModal} from '@contexts/modalContext';
import {useThemeProvider} from '@contexts/themeContext';
import { userContext } from '@contexts/userContext';
// utils
import PropTypes from 'prop-types';
import {memo, useContext} from 'react';
import logo from '@assets/logo.png';

const MobileHeader = ({title, variant, query, setQuery, isMobile}) => {
    const {handleOpen} = useModal();
    const navigate = useNavigate();

    switch (variant) {
        case 'dashboard':
            return (
                <>
                    <button className="btn btn--icon"
                            aria-label="Account"
                            onClick={() => handleOpen('account')}>
                        <i className="icon-user"/>
                    </button>
                    <NavLink className={styles.logo} to="/">
                        <img src={logo} alt="Logo" className={styles.logoClass} id="logoId" />
                    </NavLink>
                    

                    <button className="btn--icon card"
                            aria-label="Show notifications"
                            onClick={() => handleOpen('notifications')}>
                        <i className="icon-notification"/>
                    </button>
                </>
            )
        default:
            return (
                <>
                    <button className="text-16" onClick={() => navigate(-1)} aria-label="Go to previous page">
                        <i className="icon-chevron-left"/>
                    </button>
                    <h1 className={isMobile ? 'h2' : ''}>{title}</h1>
                    <button className="btn btn--icon"
                            aria-label="Account"
                            onClick={() => handleOpen('account')}>
                        <i className="icon-user"/>
                    </button>
                </>
            )
    }
}

const DesktopHeader = ({isMobile, isTablet, title, query, setQuery}) => {
    const {open, setOpen} = useSidebar();
    const {fontScale, setFontScale} = useThemeProvider();
	const userProfile = useContext(userContext);

	if (!userProfile) {
		return <div>Veriler yükleniyor...</div>;
	}
    return (
        <> 
            <NavLink className={styles.logo} to="/">
				<img src={logo} alt="Logo" className={styles.logoClass} id="logoId" />
            </NavLink>

            <div className="d-flex ">
				<div className="align-items-center g-0">
					<div className="d-flex align-items-center g-40 text-uppercase text-600">
						<div className="header-info-min">
							<p className="headerInfo fs-11">Bakiye</p>
							<h6 className="headerData fs-14 balance" id="balance">${userProfile.balance}</h6>
						</div>
						<div className="header-info-min">
							<p className="headerInfo fs-11">Kredi</p>
							<h6 className="headerData fs-14 credit" id="credit">${userProfile.credit}</h6>
						</div>
						<div className="header-info-min">
							<p className="headerInfo fs-11">Varlık</p>
							<h6 className="headerData fs-14 assets" id="assets">${userProfile.assets}</h6>
						</div>
						<div className="header-info-min">
							<p className="headerInfo fs-11">Teminat</p>
							<h6 className="headerData fs-14 margin" id="margin">${userProfile.margin}</h6>
						</div>
						<div className="header-info-min">
							<p className="headerInfo fs-11">Serbest Teminat</p>
							<h6 className="headerData fs-14 marginfree" id="marginfree">${userProfile.marginFree}</h6>
						</div>
						<div className="header-info-min">
							<p className="headerInfo fs-11">Teminat (%)</p>
							<h6 className="headerData fs-14 marginpct" id="marginpct">%{userProfile.marginPct}</h6>
						</div>
						<div className="header-info-min">
							<p className="headerInfo fs-11">Toplam Kazanç</p>
							<h6 className="headerData fs-14 totalprofit" id="totalprofit">${userProfile.floating}</h6>
						</div>
					</div>
				</div>
			 </div>
                {
                    isTablet &&
                    <button className={styles.button} onClick={() => setOpen(!open)}>
                        <i className="icon-bars-regular text-large"/>
                    </button>
                }
        </>
    )
}

const PageHeader = ({title, variant = 'main'}) => {
    const [query, setQuery] = useState('');
    const isMobile = useWindowSize().width < 990;
    const isTablet = useWindowSize().width < 1920;

    const commonProps = {
        title,
        isMobile,
        query,
        setQuery
    }

    const mobileProps = {
        ...commonProps,
        title,
        variant,
    }

    const desktopProps = {
        ...commonProps,
        isTablet,
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className={styles.container}>
                {
                    isMobile ?
                        <MobileHeader {...mobileProps}/>
                        :
                        <DesktopHeader {...desktopProps}/>
                }
            </div>
        </>
    );
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['dashboard', 'main']),
}

export default memo(PageHeader);