import React, { useState, createContext, useContext, useEffect } from 'react';
import useScrollLock from '@hooks/useScrollLock';

const ModalContext = createContext(undefined);

export const ModalProvider = ({ children }) => {
    const [modalInfo, setModalInfo] = useState({
        open: false,
        modal: null,
        props: {}
    });
    const setIsLocked = useScrollLock();

    const handleOpen = (modalType, props = {}) => {
        setModalInfo({
            open: true,
            modal: modalType,
            props
        });
        setIsLocked(true);
    };

    const handleClose = () => {
        setModalInfo({
            open: false,
            modal: null,
            props: {}
        });
        setIsLocked(false);
    };

    useEffect(() => {
        return () => {
            setIsLocked(false);
        };
    }, []);

    return (
        <ModalContext.Provider value={{ ...modalInfo, handleOpen, handleClose }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);
