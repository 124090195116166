// GA
import ReactGA from 'react-ga4';

// utils
import React, {lazy, Suspense} from 'react';

// styles
import ThemeStyles from '@styles/theme';
import './style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-grid-layout/css/styles.css';


// contexts
import ProtectedRoute from '@contexts/ProtectedRoute';
import {SidebarProvider} from '@contexts/sidebarContext';
import {ThemeProvider} from 'styled-components';
import {ModalProvider} from '@contexts/modalContext';

import { AuthProvider } from '@contexts/AuthContext';
import { UserProvider } from '@contexts/userContext';
import { SymbolsProvider } from '@contexts/symbolsContext';
import { OrdersProvider } from '@contexts/ordersContext';

import { FinanceProvider } from '@contexts/financeContext';
import { RobotsProvider } from '@contexts/robotsContext';
import { TradersProvider } from '@contexts/tradersContext';
import { WebSocketProvider } from '@contexts/WebSocketContext';

// hooks
import {useThemeProvider} from '@contexts/themeContext';
import {useEffect, useRef} from 'react';
import {useWindowSize} from 'react-use';

// components
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Loader from '@components/Loader';
import Sidebar from '@components/Sidebar';
import BottomNav from '@components/BottomNav';
import ScrollToTop from '@components/ScrollToTop';

import Dashboard from '@pages/Dashboard';
import Trade from '@pages/Trade';
import SymbolDetail from '@pages/SymbolDetail';

import Traders from '@pages/Traders';
import TraderDetail from '@pages/TraderDetail';

import Transactions from '@pages/Transactions';
import Position from '@pages/m/Position';
import Closed from '@pages/m/Closed';
import Chart from '@pages/m/Chart';

const Information = lazy(() => import('@pages/Information'));
const Deposit = lazy(() => import('@pages/Deposit'));
const Withdrawal = lazy(() => import('@pages/Withdrawal'));
const Verification = lazy(() => import('@pages/Verification'));
const Finance = lazy(() => import('@pages/Finance'));
const Settings = lazy(() => import('@pages/Settings'));
const Robots = lazy(() => import('@pages/Robots'));


const Actions = lazy(() => import('@pages/Actions'));
const Wallet = lazy(() => import('@pages/Wallet'));
const NFT = lazy(() => import ('@pages/NFT'));
const Collections = lazy(() => import('@pages/Collections'));
const SignIn = lazy(() => import('@pages/SignIn'));
const SignUp = lazy(() => import('@pages/SignUp'));


const App = () => {
    const appRef = useRef(null);
    const {theme} = useThemeProvider();
    const {width} = useWindowSize();

    useEffect(() => {
        appRef.current && appRef.current.scrollTo(0, 0);
    }, []);

    return (
		<AuthProvider>
			<SidebarProvider>
				<ThemeProvider theme={{theme: theme}}>
					<ToastContainer theme="colored" autoClose={2000} toastStyle={{borderRadius: 4}}/>
					<ModalProvider>
					{width < 990 ? <BottomNav/> : <Sidebar/>}
					<ThemeStyles/>
					<ScrollToTop />
						<RobotsProvider>
							<FinanceProvider>
								<TradersProvider>
									<WebSocketProvider>
										<UserProvider>
											<SymbolsProvider>
												<OrdersProvider>
													<div ref={appRef}>
														<Suspense fallback={<Loader visible/>}>
															<Routes>
																<Route path="/" element={ <ProtectedRoute> <Dashboard /> </ProtectedRoute> }/>
																<Route path="/trade" element={<ProtectedRoute> <Trade/> </ProtectedRoute>}/>
																<Route path="/order/:symbol" element={<ProtectedRoute> <SymbolDetail /> </ProtectedRoute>} />
																<Route path="/chart/:symbol" element={<ProtectedRoute> <Chart /> </ProtectedRoute>} />
																<Route path="/traders" element={<ProtectedRoute> <Traders/> </ProtectedRoute>}/>
																<Route path="/trader/:id" element={<ProtectedRoute> <TraderDetail /> </ProtectedRoute>} />
																<Route path="/position/:id" element={<ProtectedRoute> <Position /> </ProtectedRoute>} />
																<Route path="/closed/:id" element={<ProtectedRoute> <Closed /> </ProtectedRoute>} />
																<Route path="/transactions" element={<ProtectedRoute> <Transactions /> </ProtectedRoute>} />
																<Route path="/information" element={<ProtectedRoute> <Information/> </ProtectedRoute>}/>
																<Route path="/deposit" element={<ProtectedRoute> <Deposit/> </ProtectedRoute>}/>
																<Route path="/Withdrawal" element={<ProtectedRoute> <Withdrawal/> </ProtectedRoute>}/>
																<Route path="/verification" element={<ProtectedRoute> <Verification/> </ProtectedRoute>}/>
																<Route path="/Finance" element={<ProtectedRoute> <Finance/> </ProtectedRoute>}/>
																<Route path="/Settings" element={<ProtectedRoute> <Settings/> </ProtectedRoute>}/>
																<Route path="/actions" element={<ProtectedRoute> <Actions/> </ProtectedRoute>}/>
																<Route path="/wallet" element={<ProtectedRoute> <Wallet/> </ProtectedRoute>}/>
																<Route path="/nft" element={<ProtectedRoute> <NFT/> </ProtectedRoute>}/>
																<Route path="/collections" element={<ProtectedRoute> <Collections/> </ProtectedRoute>}/>
																<Route path="/sign-in" element={<SignIn/> }/>
																<Route path="/sign-up" element={<SignUp/>}/>
																<Route path="/robots" element={<ProtectedRoute> <Robots/> </ProtectedRoute>}/>
															</Routes>
														</Suspense>
													</div>
												</OrdersProvider>
											</SymbolsProvider>
										</UserProvider>
									</WebSocketProvider>
								</TradersProvider>
							</FinanceProvider>
						</RobotsProvider>
					</ModalProvider>
				</ThemeProvider>
			</SidebarProvider>
		</AuthProvider>
    );
}

export default App;
