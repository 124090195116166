import Spring from '@components/Spring';

import { useNavigate } from 'react-router-dom';
import {memo} from 'react';


const WalletOverview = ({withTitle = true}) => {

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userProfile');
        navigate('/sign-in');
    };

    return (
	
        <Spring className="widget">
            <div>
				<div className="modal card widget_body">
					<div className="d-flex flex-column g-8">
						<h2>Menü</h2>
						<div className="d-flex flex-column g-8">
							<a href="/wallet" className="modal-element card bordered bordered--hover">
								Hesabım <i className="icon icon-chevron-right"/>
							</a>
							<a href="/information" className="modal-element card bordered bordered--hover">
								Kişisel Bilgilerim <i className="icon icon-chevron-right"/>
							</a>
							<a href="/verification" className="modal-element card bordered bordered--hover">
								Kimlik Doğrulama <i className="icon icon-chevron-right"/>
							</a>
							<a href="/deposit" className="modal-element card bordered bordered--hover">
								Para Yatır <i className="icon icon-chevron-right"/>
							</a>
							<a href="/withdrawal" className="modal-element card bordered bordered--hover">
								Para Çek <i className="icon icon-chevron-right"/>
							</a>
							<a href="/traders" className="modal-element card bordered bordered--hover">
								Traderlar <i className="icon icon-chevron-right"/>
							</a>
							<a href="/robots" className="modal-element card bordered bordered--hover">
								Robotlar <i className="icon icon-chevron-right"/>
							</a>
							<button className="modal-element card bordered bordered--hover" onClick={handleLogout}>
								Çıkış Yap <i className="icon icon-chevron-right"/>
							</button>
						</div>
					</div>
				</div>
            </div>
        </Spring>
    )
}

export default memo(WalletOverview);