import styles from './style.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import LINKS from '@constants/links';
import { memo, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useThemeProvider } from '@contexts/themeContext';
import useAuthRoute from '@hooks/useAuthRoute';

const BottomNav = () => {
    const ref = useRef(null);
    const location = useLocation();
    const { theme } = useThemeProvider();
    const isActive = (path) => location.pathname === path;
    const isAuthRoute = useAuthRoute();
    const navigate = useNavigate();

    useEffect(() => {
        const height = ref.current.offsetHeight;
        window.document.documentElement.style.setProperty('--bottom-nav-height', `${height}px`);

        return () => {
            window.document.documentElement.style.removeProperty('--bottom-nav-height');
        }
    }, []);

    const handleNavigation = (e, path) => {
        e.preventDefault();
        navigate(path);
    };

    return (
        <>
            {
                !isAuthRoute ? (
                    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : ''}`} ref={ref}>
                        {
                            LINKS.map(link => {
                                const { icon, path, desktop, mobile } = link;
                                const isSm = icon === 'collections';

                                return (
                                    <NavLink
                                        className={`nav-link ${isActive(path) ? 'active' : ''}`}
                                        to={path}
                                        key={path}
                                        onClick={(e) => handleNavigation(e, path)}
                                    >
                                        <span className="wrapper">
                                            <i className={`icon icon-${icon} ${isSm ? 'sm' : ''}`} />
                                            <i className={`icon icon-fill ${isActive(path) ? 'active' : ''} icon-${icon}${icon !== 'trade' ? '-active' : ''} ${isSm ? 'sm' : ''}`} />
                                        </span>
                                        {mobile ? mobile : desktop}
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                ) : null
            }
        </>
    );
}

export default memo(BottomNav);
