// styling
import styles from './style.module.scss';

// components
import Star from '@ui/Star';
import NumLabel from '@ui/NumLabel';
import CurrencyItem from '@components/m/CurrencyItem';
import Spring from '@components/Spring';

import { useAuth } from '@contexts/AuthContext';
import { ordersContext } from '@contexts/ordersContext';
import { fetchCancelOrder } from '@services/apiService';
import React, { useContext } from 'react';
// hooks
import {useDispatch} from 'react-redux';
import {useWindowSize} from 'react-use';
import { useNavigate } from 'react-router-dom';

// actions
import {toast} from 'react-toastify';

const MarketItem = ({item, index, filter, withBorder}) => {

	const { refreshOrders } = useContext(ordersContext);
	const { authToken } = useAuth();
	
    const {width} = useWindowSize();
    const dispatch = useDispatch();
    const {id, amount, type, symbol, price, closePrice, lastPrice, marketLimit, stopLoss, profit, swap, margin, createdAt, closeAt} = item;
    const isCompact = width < 568 || (width >= 1440 && width < 1700) ||( width >= 1920 && width < 2000);
    const withIcon = (width >= 414 && width < 768) || (width >= 1024 && width < 1280) || width >= 1440;

	const date = new Date(createdAt);
	const formattedDate = date.toLocaleDateString('tr-TR');
	const formattedTime = date.toLocaleTimeString('tr-TR');
	const fixedProfit = Number(profit).toFixed(2);
	const dateTime = {formattedDate};

	const navigate = useNavigate();
    const handleItemClick = () => {
        if (filter === 'position') {
            navigate(`/position/${id}`);
        } else if (filter === 'closed') {
            navigate(`/closed/${id}`);
        }
    };
	
	const handleCancelWaiting = async (id, authToken) => {
		try {
			const response = await fetchCancelOrder(id, authToken);
			if (response.status) {
				await refreshOrders();
				toast.success('İptal Edildi', { autoClose: 3000 });
			}

		} catch (error) {
			toast.warning('Bir Hata Oluştu', { autoClose: 3000 });
		}
	};

    return (
       <Spring type="list" index={index} >
			<div className={`card ${withBorder ? 'bordered' : ''}`} >
				<div>
					{filter === 'position' ? (
					<>
						<div className={`${styles.container} ${withBorder ? 'bordered' : ''}`} type="list" index={index} onClick={handleItemClick}>

							<div className="flex-1">
								<div className="d-flex no-flexbox align-items-center justify-content-between">
									<div className={styles.wrapper}>
										<CurrencyItem name={symbol} label={amount} type={type} />
									</div>
									<span className="h6">{price}</span>
									<span className="h6">{lastPrice}</span>
									<span className="h6"><NumLabel value={fixedProfit} /></span>
								</div>
							</div>
						</div>
					</>
					) : filter === 'waiting' ? (
					<>
						<div className={`${styles.container} ${withBorder ? 'bordered' : ''}`} type="list" index={index} onClick={handleItemClick}>

							<div className="flex-1">
								<div className="d-flex align-items-center justify-content-between">
									<div className={styles.wrapper}>
										<CurrencyItem name={symbol} type={type} />
									</div>
									<span className="h6">{price}</span>
									<span className="h6">{amount}</span>
									<button className="btn btn--pill btn--invert" onClick={() => handleCancelWaiting(id, authToken)}>İptal</button>
								</div>
							</div>
						</div>
					</>
					) : filter === 'closed' ? (
					<>
						<div className={`${styles.container} ${withBorder ? 'bordered' : ''}`} type="list" index={index} onClick={handleItemClick}>

							<div className="flex-1">
								<div className="d-flex align-items-center justify-content-between">
									<div className={styles.wrapper}>
										<CurrencyItem name={symbol} label={amount} type={type} />
									</div>
									<span className="h6">{price}</span>
									<span className="h6">{closePrice}</span>
									<span className="h6"><NumLabel value={fixedProfit} /></span>
								</div>
							</div>
						</div>
					</>
					) : (
						<>

						</>
					)}

				</div>
			</div>
		</Spring>
    )
}

export default MarketItem