
import AppLayout from '@components/AppLayout';
import PageHeader from '@components/PageHeader';
import MStocks from '@widgets/m/Stocks';

const Trade = () => {
    return (
        <AppLayout>
            <PageHeader title="Trade"/>
			<MStocks/>
        </AppLayout>
    )
}

export default Trade